import { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Popconfirm, Spin, Modal, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet"; // For dynamic meta tags
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ADD_OR_REMOVE_POLL, ADD_VOTE, REMOVE_POLL, FETCH_SINGLE_POLL_BY_TEXT } from "../graphql/models";
import { successNotify, warnNotify } from "../util";
import { PollOption } from "../components/Home/PollOptions";
import { AddSuggession } from "../components/CreatePoll/AddSuggession";
import Comments from "./../components/Comments/Comments";
import { RestrictionTag } from "../components/Home/RestrictionTag";
import { ShareButtons } from "../components/Home/ShareButtons";

export const PollDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { category, code } = useParams();
  const poll_id = location?.state?.id;
  const basePath = `/poll/${category}/${code}`;
  const remainingPath = location.pathname.replace(basePath, "").replace(/^\//, "");
  if (remainingPath !== "") {
    const restrictions = remainingPath.split("/");
    var restrictionsString = restrictions.join("-");
  }
  const page = useSelector((state: any) => state?.auth?.page);
  console.log("pollDetails redux page: ", page);
  const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
  console.log(fullUrl);

  let questionId = restrictionsString ? `${code}-${restrictionsString}` : code;

  const {
    data,
    refetch,
    loading: fetchLoading,
  } = useQuery(FETCH_SINGLE_POLL_BY_TEXT, {
    variables: {
      questionId: questionId,
    },
  });

  const pollData = data?.FetchSinglePollByText?.poll || {};
  const totalVote = pollData?.totalVote;

  const user = useSelector((state: any) => state?.auth?.user);
  const { tags, options } = pollData || {};
  const isMine = user?._id !== undefined ? user?._id === pollData?.user?._id : false;
  const isPollVoted = options?.find(({ votes }) => votes.find((item) => item.user?._id === user?._id))?._id;

  const [addVoteMutation, { loading }] = useMutation(ADD_VOTE);
  const onAddVote = async (optionId: string) => {
    try {
      if (optionId === isPollVoted) return;
      else if (!user?._id) {
        navigate("/login");
        return;
      } else if (pollData?.user?._id === user._id) return;
      else if (pollData?.locationRestriction) {
        const normalizeValue = (value) => (value ? value.trim() : null);
        const restrictedCity = normalizeValue(pollData.locationRestriction.city);
        const restrictedState = normalizeValue(pollData.locationRestriction.locationState);

        if (restrictedCity && restrictedState) {
          if (user.city !== restrictedCity || user.locationState !== restrictedState) {
            warnNotify(`This poll is only for residents of ${restrictedCity}, ${restrictedState}`);
            return;
          }
        } else if (!restrictedCity && restrictedState) {
          if (user.locationState !== restrictedState) {
            warnNotify(`This poll is only for residents of the state of ${restrictedState}`);
            return;
          }
        } else if (restrictedCity && !restrictedState) {
          if (user.city !== restrictedCity) {
            warnNotify(`This poll is only for residents of ${restrictedCity}`);
            return;
          }
        }
      }
      if (pollData?.genderRestriction && pollData?.genderRestriction?.gender) {
        if (user?.gender !== pollData?.genderRestriction?.gender) {
          warnNotify(`This poll is only for ${pollData?.genderRestriction?.gender}s`);
          return;
        }
      }
      if (
        pollData?.ageRangeRestriction &&
        pollData?.ageRangeRestriction?.minAge !== null &&
        pollData?.ageRangeRestriction?.maxAge !== null
      ) {
        if (user?.age < pollData?.ageRangeRestriction?.minAge || user?.age > pollData?.ageRangeRestriction?.maxAge) {
          warnNotify(
            `This poll is only for people between the ages of ${pollData?.ageRangeRestriction?.minAge} and ${pollData?.ageRangeRestriction?.maxAge}`
          );
          return;
        }
      }
      const {
        data: { AddVote },
      } = await addVoteMutation({
        variables: {
          optionId,
          pollId: pollData._id,
        },
      });
      if (AddVote.success) {
        successNotify(AddVote.message);
        refetch();
      } else {
        warnNotify(AddVote.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [removePollMutation] = useMutation(REMOVE_POLL);
  const onRemovePoll = async () => {
    try {
      const {
        data: { RemovePoll },
      } = await removePollMutation({
        variables: {
          pollId: pollData._id,
        },
      });
      if (RemovePoll.success) {
        successNotify(RemovePoll.message);
        refetch();
      } else {
        warnNotify(RemovePoll.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const [addOrRemoveFollowerMutation] = useMutation(ADD_OR_REMOVE_POLL);
  const onAddOrRemoveFollower = async () => {
    try {
      const {
        data: { AddOrRemoveFollower },
      } = await addOrRemoveFollowerMutation({
        variables: {
          pollId: pollData._id,
        },
      });
      if (AddOrRemoveFollower.success) {
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const isFollowing = pollData?.followers?.some((item) => item?._id === user?._id);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>{pollData?.question}</title>
        <meta name="description" content={`Details and voting options for the poll: ${pollData?.question}`} />
      </Helmet>
      <Spin spinning={loading || fetchLoading} aria-label="Loading poll details">
        <main className="bg-white p-5 relative min-h-[90vh] lg:py-12 lg:px-24">
          {/* {data.email} */}

          <div className="text-base flex justify-between mb-5">
            <nav aria-label="Breadcrumb">
              <a className="block h-fit" href="/" onClick={handleBack} aria-label="Go back to previous page">
                <img src="/img/back.svg" alt="Back" />
              </a>
            </nav>
            <section aria-labelledby="poll-management" className="flex gap-4 items-center">
              <h2 id="poll-management" className="sr-only">
                Poll Management
              </h2>
              <button
                className="bg-slate-50 border border-slate-300 text-black rounded-full py-2 px-4 whitespace-nowrap hover:bg-slate-200"
                onClick={() => setShowModal(true)}
                aria-label="Share this poll"
              >
                <i className="fas fa-share mr-2"></i>
                Share
              </button>
              <div onClick={(e) => e.stopPropagation()} aria-label="Poll action options">
                {isMine && (
                  <Popconfirm
                    title="Are you sure?"
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{ className: "danger", danger: true }}
                    onConfirm={onRemovePoll}
                    aria-label="Confirm poll deletion"
                  >
                    <i className="far fa-trash-alt text-red-600 mr-2" aria-hidden="true"></i>
                  </Popconfirm>
                )}

                {!isMine && user?._id && (
                  <span
                    className="text-[#7C83C8] cursor-pointer"
                    onClick={onAddOrRemoveFollower}
                    aria-label={isFollowing ? "Unfollow this poll" : "Follow this poll"}
                  >
                    {/* {isFollowing ? "Unfollow" : "Follow"} */}
                    <Tooltip placement="top" title={isFollowing ? "Unfollow" : "Follow"}>
                      {isFollowing ? <i className="fas fa-star"></i> : <i className="far fa-star"></i>}
                    </Tooltip>
                  </span>
                )}
              </div>
            </section>
          </div>

          <section className="flex justify-end"></section>
          <header className="md:text-lg lg:text-2xl text-gray-700">
            <h1 dangerouslySetInnerHTML={{ __html: pollData?.question }} aria-label="Poll question"></h1>
          </header>
          <section className="text-gray-700 mb-5">
            <div dangerouslySetInnerHTML={{ __html: pollData?.description }} aria-label="Poll description"></div>
          </section>
          <section className="text-gray-700 mb-5">
            <p aria-label="Total votes">Votes: {totalVote}</p>
          </section>
          <section className="flex gap-1 mb-8" aria-label="Poll restrictions">
            <RestrictionTag
              content={
                pollData?.locationRestriction &&
                (() => {
                  const { city, locationState } = pollData.locationRestriction;
                  return city && locationState && locationState !== "null" && locationState !== ""
                    ? `${city}, ${locationState}`
                    : city || locationState;
                })()
              }
              aria-label="Poll restriction details"
            />

            <RestrictionTag
              content={pollData?.genderRestriction?.gender && `${pollData.genderRestriction.gender}s`}
              aria-label="Poll restriction details"
            />

            <RestrictionTag
              content={
                pollData?.ageRangeRestriction &&
                pollData.ageRangeRestriction?.maxAge != null &&
                pollData.ageRangeRestriction?.minAge != null &&
                (() => {
                  const { minAge, maxAge } = pollData.ageRangeRestriction;
                  return minAge && maxAge
                    ? `${minAge}yrs. - ${maxAge}yrs.`
                    : minAge
                    ? `minimum age: ${minAge}`
                    : `maximum age: ${maxAge}`;
                })()
              }
              aria-label="Poll restriction details"
            />
          </section>
          {options?.map((op) => (
            <PollOption
              {...op}
              key={op._id}
              isMine={isMine}
              isPollVoted={isPollVoted}
              totalVote={totalVote}
              onAddVote={onAddVote}
              refetch={refetch}
              pollId={pollData._id}
            />
          ))}
          {pollData?.suggestionable && !isMine && user?._id && pollData?.user?._id && (
            <AddSuggession refetch={refetch} pollId={pollData._id} />
          )}
          <hr className="mt-10 border-t border-gray-300" aria-hidden="true" />
          <Comments currentUserId={user?._id} pollId={poll_id} questionId={questionId} />
          <Modal title="Share this poll" visible={showModal} onCancel={() => setShowModal(false)} footer={null}>
            <ShareButtons url={window.location.href} title={pollData?.question} />
          </Modal>
        </main>
      </Spin>
    </>
  );
};
