import { PlusCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Drawer, Dropdown, MenuProps, Modal, Tooltip } from "antd";
import { useState } from "react";
import { CreatePolls } from "../pages";
import { useAppDispatch } from "../hooks";
import { onUserLogout, setSearchCategory, setPage, resetPollsFilters, setSearchText } from "../store/slices/auth.slice";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import HeaderSearchBar from "./HeaderSearchBar";
import Notifications from "./Notification";

export const Header = () => {
  const [drawer, setDrawer] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user, isAuthenticated } = useSelector((state: any) => state?.auth || {});

  const showDrawer = () => {
    setDrawer(!drawer);
  };

  const onLogout = () => {
    dispatch(onUserLogout({}));
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <Link to="/my-polls">
          <i className="fas fa-th mr-3" />
          <span className="text-base font-semibold">My Polls</span>
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link to="/voted-polls">
          <i className="fas fa-vote-yea mr-3" />
          <span className="text-base font-semibold">Voted Polls</span>
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link to="/followed-polls">
          <i className="fas fa-star mr-3" />
          <span className="text-base font-semibold">Followed Polls</span>
        </Link>
      ),
      key: "2",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Link to="/settings">
          <i className="fas fa-cog mr-3" />
          <span className="text-base font-semibold">Settings</span>
        </Link>
      ),
      key: "3",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div onClick={onLogout}>
          <i className="fas fa-sign-out-alt text-red-500 mr-3" />
          <span className="text-base font-semibold">Logout</span>
        </div>
      ),
      key: "4",
    },
  ];

  return (
    <section id="navbar" className="py-4 sticky top-0 bg-headerBlue shadow-xl z-30">
      <div className="px-3">
        <div className="flex items-center justify-between">
          {/* logo */}
          <a
            className="w-[240px] hidden md:block cursor-pointer"
            href="/"
            onClick={(e) => {
              e.preventDefault();
              dispatch(setPage(1));
              dispatch(setSearchText({ searchText: "" }));
              dispatch(setSearchCategory({ searchCategory: "" }));
              dispatch(resetPollsFilters());
              navigate("/");
              document.title = "PollsBoard";
            }}
          >
            <img src="/img/logo1.png" alt="Logo" />
          </a>
          {/* search bar */}
          <HeaderSearchBar />

          {/* mobile navbar */}
          <Button type="primary" onClick={showDrawer} className="md:hidden bg-gray-700 text-white">
            <i className="fas fa-bars fa-lg"></i>
          </Button>
          <div onClick={showDrawer} className="hidden">
            <Drawer title="Menu" placement="right" open={drawer} width={250}>
              {/* <Link to="/">
                <p className="font-bold bg-gray-200 hover:bg-gray-300 p-3 rounded-md mb-2">
                  <i className="fas fa-home mr-2"></i> Home
                </p>
              </Link> */}
              {isAuthenticated && (
                <>
                  <Link to="#" role={"button"} onClick={() => setOpen(true)}>
                    <p className="font-bold bg-gray-200 hover:bg-gray-300 p-3 rounded-md mb-2">
                      <i className="fas fa-poll mr-2"></i> Create Poll
                    </p>
                  </Link>

                  <Link to="/my-polls">
                    <p className="font-bold bg-gray-200 hover:bg-gray-300 p-3 rounded-md mb-2">
                      <i className="fas fa-th mr-2"></i> My Polls
                    </p>
                  </Link>
                  <Link to="/voted-polls">
                    <p className="font-bold bg-gray-200 hover:bg-gray-300 p-3 rounded-md mb-2">
                      <i className="fas fa-vote-yea mr-2"></i> Voted Polls
                    </p>
                  </Link>
                  <Link to="/followed-polls">
                    <p className="font-bold bg-gray-200 hover:bg-gray-300 p-3 rounded-md mb-2">
                      <i className="fas fa-star mr-2"></i> Followed Polls
                    </p>
                  </Link>
                  <Link to="/settings">
                    <p className="font-bold bg-gray-200 hover:bg-gray-300 p-3 rounded-md mb-2">
                      <i className="fas fa-cog mr-2"></i> Settings
                    </p>
                  </Link>

                  <Link to="#" role={"button"} onClick={onLogout}>
                    <p className="font-bold bg-gray-200 hover:bg-gray-300 p-3 rounded-md mb-2">
                      <i className="fas fa-sign-out-alt text-red-500 mr-2"></i> Logout
                    </p>
                  </Link>
                </>
              )}
              {!isAuthenticated && (
                <Link to="/login">
                  <p className="font-bold bg-gray-200 hover:bg-gray-300 p-3 rounded-md">
                    <i className="fas fa-sign-in-alt mr-2"></i> Login/Register
                  </p>
                </Link>
              )}
            </Drawer>
          </div>
          {/* desktop navbar */}
          <div className="hidden md:block md:w-auto">
            <ul className="flex bg-[#182341] text-white">
              {isAuthenticated && (
                <>
                  <li>
                    <Tooltip title="Create Poll">
                      <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className="mr-2 font-semibold flex items-center"
                      >
                        <PlusCircleOutlined className="text-2xl" />
                      </button>
                    </Tooltip>
                  </li>
                  <li className="px-2 flex items-center">
                    <Dropdown menu={{ items }} trigger={["hover"]} placement="bottom">
                      <a
                        href="/my-account"
                        onClick={(e) => e.preventDefault()}
                        className="font-semibold flex items-center"
                      >
                        <UserOutlined className="text-2xl" />
                      </a>
                    </Dropdown>
                  </li>
                  <li className="px-2 flex items-center">
                    <a href="/notifications" className="text-white flex items-center">
                      <Notifications userId={user._id} />
                    </a>
                  </li>
                </>
              )}
              {!isAuthenticated && (
                <li className="pr-2 flex items-center">
                  <Link to="/login" className="font-semibold hover:border-b-2 hover:border-b-white flex items-center">
                    Login/Register
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
        <Modal width={700} footer={null} open={open} onCancel={() => setOpen(false)}>
          <CreatePolls setOpen={setOpen} notFullHeight={true} />
        </Modal>
      </div>
    </section>
  );
};
