import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import { FETCH_POLLS } from "../graphql/models";
import { setPage } from "../store/slices/auth.slice";
import { Pagination, Spin } from "antd";
import { Container, PollCard } from "../components";

export const Home = () => {
  console.log("Home component re-rendered");
  const { page = 1, searchTag, searchText, searchCategory, pollsFilters } = useSelector(
    (state: any) => state?.auth
  );
  console.log("redux page on home", page);
  const [limit] = useState(9);
  // const [localPage, setLocalPage] = useState(page);
  const dispatch = useDispatch();
  let offset = (page - 1) * limit;
  const { data, loading, refetch } = useQuery(FETCH_POLLS, {
    variables: {
      limit,
      offset,
      searchText,
      searchTag,
      searchCategory,
      pollsFilters: pollsFilters,
    },
    fetchPolicy: "cache-and-network",
  });
  const polls = data?.FetchPoll?.result || [];
  console.log("polls", polls);
  const total = data?.FetchPoll?.count || 0;
  console.log("total", total);

  const onChangePagination = (p) => {
    dispatch(setPage(p));
    // setLocalPage(p);
  };

  useEffect(() => {
      refetch({
        limit,
        offset,
        searchText,
        searchTag,
        searchCategory,
        pollsFilters,
      });
  }, [searchText, searchTag, searchCategory, pollsFilters, limit, offset, refetch]);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <section id="card" className="bg-slate-200 py-10">
          <Container>
            {loading ? (
              <Spin spinning={loading}>
                <div style={{ minHeight: "300px" }} />
              </Spin>
            ) : (
              <div id="get_height">
                {polls.length === 0 && (
                  <p className="w-full pt-5 text-center font-bold text-black">There is no poll</p>
                )}
                <div className="grid grid-cols-12 gap-y-12 gap-x-0 sm:gap-x-12">
                  {polls?.map((poll: any) => (
                    <PollCard key={poll?._id} data={poll} totalVote={poll.totalVote} refetch={refetch} localPage={page} />
                  ))}
                </div>
              </div>
            )}
          </Container>
        </section>
      </div>
      <div className="mb-7">
        <Pagination
          className="flex justify-center"
          total={total}
          pageSize={limit}
          current={page}
          showSizeChanger={false}
          onChange={onChangePagination}
        />
      </div>
    </div>
  );
};
